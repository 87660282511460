import { useEffect } from "react"
import { navigate } from "gatsby"

// import Layout from "layout/Layout"
// import SEO from "layout/SEO"
// import PatientEnrollment from "../components/Enrollment/PatientEnrollment"

export default () => {
  useEffect(() => {
    navigate("/")
  }, [])

  return null
  // return (
  //   <Layout>
  //     <SEO title="Enroll as Patient" />
  //     <PatientEnrollment />
  //   </Layout>
  // )
}
